import nacl from 'tweetnacl';
import Peer, {PeerConfig} from './peer';
import Subscribable from "./subscribable";
import {ConnectionFailedError} from "./errors";
import {getLogger} from "./logger";


const debug = getLogger('tracker');

/**
 * Wrapper for a Switchboard-specific {@link Peer} object, containing extra metadata from Trackers.
 *
 * These are automatically created by {@link Switchboard}, and you should not attempt to manually create one.
 */
export class ConnectedPeer extends Peer {
    private offer: Offer|null = null;
    public readonly offerID: string = Buffer.from(nacl.randomBytes(20)).toString('hex');

    /**
     * The ID this peer has used to identify themselves, cross-tracker.
     */
    public id: string = '';

    /**
     * Store the ping timeout with each peer.
     */
    public timeoutTracker: any = null;

    /**
     * Generates an Offer object from this Peer. If one already exists, returns that instead.
     */
    async generateOffer(): Promise<Offer> {
        if (this.offer) return this.offer;

        return new Promise((res, rej) => {
            const cleanup = this.once('error', (err: any) => {
                debug('Failed to generate peer offer: ' + err);
                rej(err);
            });

            this.once('handshake', (offer: string) => {
                const off: Offer = {
                    offer,
                    offer_id: ''+this.offerID
                };
                this.offer = off;
                cleanup();
                res(off);
            });

            this.handshake('', true);
        });
    }
}


/**
 * General wrapper to accommodate the general packet structure exchanged with each Tracker.
 * @internal
 */
export interface AnnouncePacket {
    info_hash: string;
    peer_id: string;
    numwant: number;
    downloaded: number;
    left: number;
    event?: string;
    action: "announce";
    offers?: Offer[];
    offer?: any;
    answer?: any;
    offer_id?: string;
    interval?: number;
    'min interval'?: number;
    'failure reason'?: string;
    'tracker id'?: string;
}

/**
 * Representation of the offer packet generated by each Peer.
 * @internal
 */
interface Offer {
    offer: any;
    offer_id: string;
}


/**
 * The exposed Tracker properties and methods.
 * @internal
 */
export interface TrackerConnectorInterface {
    /**
     * Triggered when the connection is lost to the Tracker server.
     * @param event
     * @param callback
     * @returns A function to call, in order to unsubscribe.
     */
    on(event: 'disconnect', callback: Function): () => void;

    /**
     * Triggered when the connection is established to the Tracker server.
     * @param event
     * @param callback
     * @returns A function to call, in order to unsubscribe.
     */
    on(event: 'connect', callback: Function): () => void;

    /**
     * Triggered when a new Peer object is located and connected.
     * @param event
     * @param callback
     * @returns A function to call, in order to unsubscribe.
     */
    on(event: 'peer', callback: {(peer: ConnectedPeer): void}): () => void;

    /**
     * Triggered when this TrackerConnector is unrecoverably killed.
     * @param event
     * @param callback A function that can receive the Error, if any, that caused termination.
     * @returns A function to call, in order to unsubscribe.
     */
    on(event: 'kill', callback: {(err: Error|null): void}): () => void;

    /**
     * Determine if this Tracker is open and available.
     */
    isOpen: boolean;

    /**
     * Connect to this tracker. Creates a new WebSocket & binds callbacks.
     */
    connect(): void;

    /**
     * Kill this WebSocket connection to the Tracker, and disable reconnection.
     *
     * Peers connected to via this tracker will NOT be closed by calling this.
     */
    kill(): void;
}


/**
 * Naive implementation of the WebsSocket matchmaking protocol used by WebTorrent services.
 *
 * Registers at the given server, then returns Peers (`simple-peer` objects) once they are connected & ready.
 * @internal
 * @hidden
 */
export class TrackerConnector extends Subscribable implements TrackerConnectorInterface{
    private readonly url: string;
    private readonly peerID: string;
    private readonly infoHash: string;
    private readonly peerConfig: PeerConfig;
    private readonly isBlacklisted: Function;
    private shouldReconnect: boolean = true;
    private timer: any = null;
    private sock: WebSocket|null = null;
    private initiatorPeers: Record<string, ConnectedPeer> = {};
    private currentAnnounceInterval: number;
    private introPending: boolean = true;
    private connectTries: number = 0;
    private trackerID: string|null = null;
    private didConnect: boolean = false;
    private connectionTimer: any;
    private readonly wantedPeerCount: number;
    private readonly trackerTimeout: number;
    private readonly maxReconnectAttempts: number;

    /**
     * Create and connect to a new Tracker, using a websocket URL.
     * @param trackerURL The "ws" or "wss" tracker URL to join.
     * @param peerID The ID to identify this client. Reuse this across all trackers.
     * @param infoHash The "info hash" to register with the Tracker. This is used as a connection ID.
     * @param peerConfig An object with additional params to pass into each created simple-peer Peer object.
     * @param isBlacklisted A function, which decides if a given Peer ID may connect pre-handshake.
     * @param announceInterval The interval, in milliseconds, that this tracker will re-announce.
     * @param wantedPeerCount The total number of peers ideally wanted from this tracker each announce.
     * @param trackerTimeoutMs The total time to wait before timing out. This may also happen sooner if the tracker resolves faster.
     * @param maxReconnectAttempts The maximum allowed attempts to reconnect before killing this tracker.
     */
    constructor(trackerURL: string,
                peerID: string,
                infoHash: string,
                peerConfig: PeerConfig,
                isBlacklisted: Function,
                announceInterval: number,
                wantedPeerCount: number,
                trackerTimeoutMs: number,
                maxReconnectAttempts: number
    ) {
        super();
        this.url = trackerURL;
        this.peerID = peerID;
        this.isBlacklisted = isBlacklisted;
        this.infoHash = Buffer.from(infoHash, 'hex').toString('binary');
        this.peerConfig = peerConfig;
        this.currentAnnounceInterval = announceInterval;
        this.wantedPeerCount = wantedPeerCount;
        this.trackerTimeout = trackerTimeoutMs || 5000;
        this.maxReconnectAttempts = maxReconnectAttempts;
    }

    /**
     * If this current tracker's websocket is open.
     */
    get isOpen() {
        return this.sock?.readyState === WebSocket.OPEN;
    }

    /**
     * Connect to this tracker. Creates a new WebSocket & binds callbacks.
     */
    connect() {
        this.connectionTimer = setTimeout(() => {
            if (!this.didConnect) {
                this.onError(new Error("Failed to connect in time!"));
            }
        }, this.trackerTimeout);
        this.sock = new WebSocket(this.url);
        this.sock.onclose = this.reconnect.bind(this);
        this.sock.onerror = this.onError.bind(this);
        this.sock.onopen = this.onConnect.bind(this);
        this.sock.onmessage = this.onMessage.bind(this);
    }

    /**
     * Close the current WebSocket connection, and cleans up all pending offers.
     *
     * This is for internal use only. Use `kill()` instead, when manually disconnecting.
     * @private
     */
    private close() {
        debug('closing tracker socket.');
        try {
            this.sock?.close();
        } catch (ignored) {}
        this.setAnnounceTimer(null);
        [...Object.values(this.initiatorPeers)].forEach(p => this.retractOffer(p, true));
        if (this.didConnect) {
            this.emit('disconnect');
        }
    }

    /**
     * Called when the WebSocket disconnects, to trigger automatic reconnection with a rate-limited timer.
     * @private
     */
    private reconnect() {
        if (!this.shouldReconnect) return;

        clearTimeout(this.timer);

        this.connectTries+=1;
        if (this.connectTries > this.maxReconnectAttempts) {
            return this.kill(new Error('Failed to connect within the allowed max attempts.'));
        }

        this.timer = setTimeout(() => {
            this.close();
            this.connect();
        }, Math.min(this.connectTries, 5) * 2000);
    }

    /**
     * Send the given Object to the WebSocket tracker.
     * @param message {object} Any serializable object.
     * @private
     */
    private send(message: any) {
        if (this.sock) {
            this.sock.send(JSON.stringify(message));
        }
    }

    /**
     * Called when the WebSocket connects to the tracker.
     * Automatically handles the handshake packet & schedules announcements.
     * @private
     */
    private onConnect() {
        debug('Connecting to tracker:', this.url)
        const intro: AnnouncePacket = {
            action: "announce",
            event: "completed",
            downloaded: 0,
            info_hash: this.infoHash,
            left: 0,
            numwant: this.wantedPeerCount,
            peer_id: this.peerID
        }
        this.introPending = true;
        this.send(intro);
        this.setAnnounceTimer(this.currentAnnounceInterval);
        this.emit('connect');
        clearTimeout(this.connectionTimer);
    }

    /**
     * Called when the tracker experiences an error.
     * If a connection was previously established, reconnects.
     * Otherwise, kills this tracker with an ConnectionFailedError.
     * @param error
     * @private
     */
    private onError(error: Event|Error) {
        debug('WS Error:', error, this.url);
        if (this.didConnect) {
            this.didConnect = false;
            this.reconnect();
        } else {
            this.kill(new ConnectionFailedError('No-Retry: Connection could not be established to websocket host.'));
        }
    }

    /**
     * Called when a message is received from the Tracker.
     * Handles changes in announcement rate, error messages, and peer introductions.
     * @param event {MessageEvent} The websocket message event.
     * @private
     */
    private onMessage(event: MessageEvent) {
        const msg: AnnouncePacket = JSON.parse(event.data);

        debug('Incoming Tracker Data:', msg);

        if (msg['failure reason']) {
            return this.kill(new Error(`Tracker error response: "${msg['failure reason']}"`));
        }

        this.didConnect = true;
        this.connectTries = 0;

        const trackerID = msg['tracker id']
        if (trackerID) {
            this.trackerID = trackerID
        }

        // First-time handshake packet:
        if (this.introPending) {
            this.introPending = false;
            this.getAnnouncePacket('started', 10).then(packet => {
                this.send(packet);
            }).catch(err => {
                this.kill(err);
            })
        }

        // Check blacklist:
        if (msg.peer_id && this.isBlacklisted(msg.peer_id)) {
            debug('Ignoring blacklisted client:', msg.peer_id);
            return;
        }

        // Peer has extended an offer to us:
        if (msg.offer && msg.peer_id && msg.offer_id) {
            debug('Joining Peer:', msg.peer_id);
            const offerID = msg.offer_id;
            const peer = this.makePeer(false);

            if (!peer.id) peer.id = msg.peer_id;
            peer.once('handshake', (answer: string) => {
                const params: any = {
                    action: 'announce',
                    info_hash: this.infoHash,
                    peer_id: this.peerID,
                    to_peer_id: msg.peer_id,
                    answer,
                    offer_id: offerID
                }
                if (this.trackerID) params.trackerID = this.trackerID;

                this.send(params);
            });
            peer.once('error', (err: any) => {
                debug(err);
                peer.close();
            });
            peer.timeoutTracker = setTimeout(() => {
                peer.close();
            }, 15000);
            peer.handshake(msg.offer, false).catch(console.error);
        }

        // Peer has accepted one of our offers:
        if (msg.answer && msg.peer_id && msg.offer_id) {
            const peer = this.initiatorPeers[msg.offer_id];

            if (peer) {
                if (!peer.id) peer.id = msg.peer_id;
                peer.handshake(msg.answer, false).catch(console.error);
            } else {
                debug('Missing tracker peer:', msg);
            }
        }
    }

    /**
     * Builds an announcement packet, for the given event.
     * If invites is specified, also generates that amount of WebRTC SDP Offers.
     * @param event The type of event to provide in the packet, or `null` to exclude.
     * @param invites {number} The total number of offers we want to have.
     * @private
     */
    private async getAnnouncePacket(event?: "started"|"complete"|null, invites:number = 0): Promise<AnnouncePacket> {
        const ret: AnnouncePacket = {
            action: "announce",
            downloaded: 0,
            info_hash: this.infoHash,
            left: 0,
            numwant: this.wantedPeerCount,
            peer_id: this.peerID
        }
        if (event) ret.event = event;

        if (invites) {
            // Make sure we've got the correct amount of available peers, then generate their offers.
            const missing = invites - Object.keys(this.initiatorPeers).length;
            for (let i=0; i < missing; i++) {
                this.makePeer(true);
            }
            ret.offers = await Promise.all(Object.values(this.initiatorPeers).map(p => p.generateOffer()));
        }

        return ret;
    }

    /**
     * Remove the given offerID, and cancel the corresponding peer if specified.
     *
     * If the offer is not in the "last chance" phase, only removes it from the list of tracked offers.
     * @param peer
     * @param killPeer {boolean} If the offer is no longer valid, cancel the peer if true.
     * @private
     */
    private retractOffer(peer: ConnectedPeer, killPeer: boolean = true) {
        debug('Retracting:', peer.offerID, '- kill:', killPeer);

        if (killPeer) {
            peer.close();
        }
        delete this.initiatorPeers[peer.offerID];
    }

    /**
     * Generates a peer object, using the config provided in the creation of this Tracker.
     *
     * Automatically registers the Peer to clear its own offering if a connection is established.
     * @param initiator {boolean} If this Peer will be an initiator - and thus should generate an offer.
     * @private
     */
    private makePeer(initiator: boolean): ConnectedPeer {
        const peer = new ConnectedPeer({
            ...this.peerConfig,
            trickleICE: false
        });

        peer.once('connect', () => {
            clearTimeout(peer.timeoutTracker);
            peer.removeAllListeners('error');
            this.retractOffer(peer, false);
            this.onPeerConnected(peer);
        });

        peer.permanent('close', () => {
            if (this.initiatorPeers[peer.offerID]) {
                this.retractOffer(peer, false);
            }
        });

        if (initiator) {
            this.initiatorPeers[peer.offerID] = peer;
        }

        return peer;
    }

    /**
     * Triggered by each simple-peer Peer object if it establishes an open connection to another User.
     * @param peer The Peer object that has just become open for data transmission.
     * @private
     */
    private onPeerConnected(peer: ConnectedPeer) {
        debug('Tracker connected to peer:', peer, peer.id);
        this.emit('peer', peer);
    }

    /**
     * Stops the current announcement timer, and reschedules a new one.
     * If the given interval is null, does not reschedule.
     * @param interval
     * @private
     */
    private setAnnounceTimer(interval: number|null) {
        debug('Announce Timer Interval:', interval, this.url);

        clearTimeout(this.timer);
        this.timer = null;

        if (interval !== null) {
            this.currentAnnounceInterval = interval;
            this.timer = setTimeout(this.reAnnounce.bind(this), interval);
        }
    }

    /**
     * Called automatically on a timer to send the latest Announcement Packet data to the Tracker.
     * @private
     */
    private async reAnnounce() {
        if (!this.sock) return;

        const packet = await this.getAnnouncePacket(null, 10).catch(this.kill);

        debug('Sending re-announce:', packet);

        this.send(packet);

        debug('Re-announced. Peers:', Object.keys(this.initiatorPeers).length, this.url);
        this.setAnnounceTimer(this.currentAnnounceInterval);
    }

    protected emit(event: 'disconnect'|'connect'|'peer'|'kill', val?: any) {
        super.emit(event, val);
    }

    /**
     * Kill this WebSocket connection to the Tracker, and disable reconnection.
     *
     * All cleanup handled by the internal `websocket.onclose` handler will also be applied as a result.
     */
    public kill(err?: any) {
        debug('Tracker kill error:', err);
        this.shouldReconnect = false;
        this.close();
        this.emit('kill', err||null);
    }
}
